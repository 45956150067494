import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
    Snackbar, Grid, TextField, createStyles,
    withStyles, makeStyles
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { useForm, Controller } from 'react-hook-form'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Master from '../components/Master'
import withLocation from '../components/withLocation'
import Button from '../ui/Button'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const defaultValues = {
    password: "",
    confirmPassword: ""
}

const ResetPassword = ({ search }) => {

    const data = useStaticQuery(graphql`
        query {
            page(id: {eq: "5e8e38d1502da50f50c2926d"}) {
                title
                description
                details
                keywords
            }
            MAIN_BACKGROUND_COLOR: setting(environmentName: {eq: "MAIN_BACKGROUND_COLOR"}) {
                value
            }
        }
    `)
    const page = data.page
    const MAIN_BACKGROUND_COLOR = data.MAIN_BACKGROUND_COLOR.value

    const CssTextField = withStyles({
        root: {
            '& label.Mui-focused': {
                color: `${MAIN_BACKGROUND_COLOR}`,
            },
            '& label': {
                fontFamily: 'Open Sans',
                fontSize: '14px'
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: `${MAIN_BACKGROUND_COLOR}`,
            },
            '& .MuiInputBase-input': {
                fontFamily: 'Open Sans',
                fontSize: '14px'
            },
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: `${MAIN_BACKGROUND_COLOR}`,
                },
                '&:hover fieldset': {
                    borderColor: `${MAIN_BACKGROUND_COLOR}`,
                },
                '&.Mui-focused fieldset': {
                    borderColor: `${MAIN_BACKGROUND_COLOR}`,
                },
            },
        },
    })(TextField)

    const useStyles = makeStyles(theme =>
        createStyles({
            root: {
                display: 'flex',
                flexWrap: 'wrap',
            },
            width: {
                width: '100%'
            },
        }),
    )

    const classes = useStyles()

    const [open, setOpen] = useState(false)

    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('')

    const snackbar = (message, severity) => {
        setMessage(message)
        setSeverity(severity)
        setOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }
    const methods = useForm()
    const { handleSubmit, control, reset } = methods
    const onSubmit = data => {

        const id = search.id;
        const password = data.password;
        const confirmPassword = data.confirmPassword;

        if (data.password === undefined) {
            snackbar('Preencha a senha.', 'error')
        }
        if (data.confirmPassword === undefined) {
            snackbar('Preencha a Confirmação da senha.', 'error')
        }
        const graphqlQuery = {
            query: `
            mutation ResetPassword ($id: ID!, $password: String!, $confirmPassword: String!){
                resetPassword(id: $id, password: $password, confirmPassword: $confirmPassword) {
                    _id
                }
          }
          `,
            variables: {
                id,
                password,
                confirmPassword
            }
        }

        fetch(`${process.env.API_URL}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(graphqlQuery)
        })
            .then(res => {
                return res.json()
            })
            .then(resData => {
                if (resData.errors) {
                    if (resData.errors[0].status === 422) {
                        if (resData.errors[0].data[0].message === 'Password needs at least 5 characters.') {
                            snackbar('A senha deve ter no mínimo 5 caracteres.', 'error')
                        }
                        else if (resData.errors[0].data[0].message === 'The password and confirmation password do not match.') {
                            snackbar('A Senha e a Confirmação da senha não conferem.', 'error')
                        }
                        else if (resData.errors[0].data[0].message === 'Email cannot be null.') {
                            snackbar('Preencha o campo Email.', 'error')
                        }
                        else if (resData.errors[0].data[0].message === 'Telephone cannot be null.') {
                            snackbar('Preencha o campo WhatsApp / Telefone.', 'error')
                        }
                        else if (resData.errors[0].data[0].message === 'Message cannot be null.') {
                            snackbar('Preencha o campo Mensagem.', 'error')
                        }
                        else {
                            snackbar(resData.errors[0].data[0].message, 'error')
                        }
                    }
                    if (resData.errors[0].status === 404) {
                        if (resData.errors[0].includes('No recovery password found!')) {
                            snackbar('A solicitação para alteração da senha não foi encontrada', 'error')
                        }
                        else if (resData.errors[0].message.includes('No user found!')) {
                            snackbar('Usuário não encontrado.', 'error')
                        }
                        else if (resData.errors[0].message.includes('The password reset deadline has expired.')) {
                            snackbar('O prazo para alteração da senha expirou', 'error')
                        }
                        else if (resData.errors[0].message.includes('The password has already been changed.')) {
                            snackbar('A senha já foi alterada por esta requisição.', 'error')
                        }
                        else if (resData.errors[0].message.includes('The password has already been changed.')) {
                            snackbar('A senha já foi alterada por esta requisição.', 'error')
                        }
                        else {
                            snackbar(resData.errors[0].message, 'error')
                        }
                    }
                    if (resData.errors[0].status === 500) {
                        if (resData.errors[0].message.includes('Cast to ObjectId failed for value')) {
                            snackbar('Requisição troca de senha inválida.', 'error')
                        }
                        else {
                            snackbar(resData.errors[0].message, 'error')
                        }
                    }
                }
                else {
                    snackbar('Senha alterada com sucesso!', 'success')
                    reset({ defaultValues })
                }
            })
            .catch(err => {
                snackbar('Erro ao alterar senha, verifique todos os campos e tente novamente.', 'error')
            })

    }

    return (
        <Master title={page.title}>
            <GatsbySeo
                title={page.title}
                description={page.description}
                canonical={`${process.env.SITE_URL}`}
                noindex={true}
                nofollow={true}
                openGraph={{
                    url: `${process.env.SITE_URL}`,
                    title: page.title,
                    description: page.description,
                    images: [
                        {
                            url: `${process.env.SITE_IMAGE_OG_512}`,
                            width: 512,
                            height: 512,
                            alt: page.title
                        }
                    ],
                    site_name: `${process.env.SITE_NAME}`,
                }}
                twitter={{
                    handle: '@handle',
                    site: '@site',
                    cardType: 'summary_large_image',
                }}
            />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <h1>{page.title}</h1>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <div dangerouslySetInnerHTML={{ __html: page.details }} />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller as={CssTextField} name="password" control={control} label="Senha" className={classes.width} type="password" />
                        <Controller as={CssTextField} name="confirmPassword" control={control} label="Confirmação da senha" className={classes.width} type="password" />
                        <p>
                            <Button>
                                Redefinir senha
                            </Button>
                        </p>
                    </form>
                </Grid>
            </Grid>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </Master>
    )
}

export default withLocation(ResetPassword)